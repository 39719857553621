/* Rimuove tutti i bordi e gli ombreggiamenti interni */
.fc,
.fc *:not(.available-event) {
  border: none !important;
  box-shadow: none !important;
}

.fc-event.available-event {
  border: 2px solid #d10c33 !important;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); /* Ombra più pronunciata */
  cursor: pointer !important;
  transition: transform 0.2s ease, background-color 0.2s ease;
  border-radius: 6px !important; /* Bordi arrotondati */
  margin: 2px 0 !important; /* Spazio tra gli slot */
}

colgroup col {
  width: 40px !important;
  text-align: center;
}

/* Aggiunge un bordo tra le colonne dei giorni nella vista settimanale */
.fc .fc-timegrid-col {
  border-left: 2px solid #eeeeee !important;
}
/* Aggiunge il bordo superiore solo agli slot orari principali */
.fc-timegrid-slot.fc-timegrid-slot-lane:not(.fc-timegrid-slot-minor) {
  border-top: 2px solid #eeeeee !important; /* Bordo superiore per gli slot principali */
}

/* Cambia l'altezza degli slot orari */
.fc-timegrid-slot {
  height: 40px !important; /* Aumentato per rendere gli slot più grandi e facili da cliccare */
}

/* Stile per mobile */
@media (max-width: 768px) {
  .fc-timegrid-slot {
    height: 40px !important; /* Ancora più grande su mobile per facilitare il tocco */
  }
  .fc-event {
    min-height: 40px !important; /* Allarga l'altezza minima dell'evento su mobile */
    line-height: 1.5 !important; /* Regola l'altezza della linea all'interno degli eventi */
    font-size: 1em !important; /* Testo più grande su mobile */
  }
}

/* Cambia l'altezza degli eventi */
.fc-event {
  min-height: 40px !important; /* Allarga l'altezza minima dell'evento */
  line-height: 1.5 !important; /* Regola l'altezza della linea all'interno degli eventi */
}

.fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
  margin-top: -26px; /* Questo sposta l'orario verso l'alto */
  display: flex;
  justify-content: center;
}

.fc-toolbar-title {
  text-transform: capitalize;
}

/* Rimuove il bordo a sinistra delle ore */
.fc .fc-timegrid .fc-timegrid-col:first-child {
  border-left: none !important;
}

/* Rimuove il bordo a sinistra della prima giornata */
.fc .fc-timegrid-col:nth-child(2) {
  border-left: none !important;
}

/* Intestazioni dei giorni */
.fc .fc-col-header-cell {
  text-align: center;
}

/* Cella del giorno corrente */
.fc-day-today .custom-day-header {
  background-color: #fff0f0;
  color: #d10c33;
}

/* Toolbar */
.fc-toolbar {
  padding: 2px;
}

/* Pulsanti della toolbar */
.fc-toolbar .fc-button {
  background-color: #f3e8e8;
  color: #d10c33;
  border-radius: 15px;
  padding: 8px 12px;
  cursor: pointer;
}

.fc .fc-button-primary:disabled {
  background-color: #f3e8e8;
  color: #d10c33;
}

.fc-toolbar .fc-button:focus,
.fc-toolbar .fc-button:active {
  border: none !important;
  outline: none !important;
  box-shadow: none !important; /* Rimuove eventuali ombre */
  background-color: #f3e8e8;
  color: #d10c33;
}

.days-slider {
  transition: scroll 0.3s ease; /* Transizione per lo scorrimento fluido */
}

.day-item {
  text-align: center;
  padding: 20px 12px;
  margin: 0 12px; /* Spazio tra gli elementi */
  border-radius: 15px;
  transition: background-color 0.3s ease, color 0.3s ease;
  color: #333;
}

.day-item.selected {
  background-color: #fff0f0;
  color: #d10c33;
  font-weight: bold;
  border-radius: 15px; /* Forma arrotondata per il giorno selezionato */
}

.day-item.disabled {
  color: #ccc; /* Colore grigio per i giorni disabilitati */
  cursor: not-allowed; /* Cambia il cursore per indicare che non è cliccabile */
  user-select: none; /* Impedisce la selezione del testo */
  pointer-events: none; /* Impedisce l'interazione con l'elemento */
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 15px;
  font-weight: bold;
}
/* @media (max-width: 1024px) {
  thead {
    display: none;
  }
  .fc-col-header {
    display: none !important; 
  }
} */

@media (hover: hover) {
  .fc-event.available-event:hover,
  .fc-event.available-event.active {
    transform: scale(1.05) translateY(-2px);
    transition: transform 0.3s ease;
    background-color: #d10c33 !important;
    color: white !important;
  }

  .fc-event-main:hover,
  .fc-event-main.active {
    background-color: #d10c33 !important; /* Forza il rosso acceso */
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4) !important; /* Ombra più pronunciata al passaggio del mouse */
    color: white !important;
  }
}

@media (pointer: coarse) {
  .fc-event.available-event.active {
    transform: scale(1.05) translateY(-2px);
    transition: transform 0.3s ease;
  }

  .fc-event-main.active {
    background-color: #d10c33 !important; /* Stesso colore acceso del desktop */
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4) !important; /* Ombra più pronunciata */
    color: white !important;
  }
  
  /* Stili specifici per dispositivi touch per rendere gli slot più facili da cliccare */
  .slot-clickable {
    min-height: 40px !important;
    font-size: 1em !important;
    font-weight: bold !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

.fc-event.bookedLesson {
  border: 1px solid #d10c33 !important;
  background: #f3e8e8;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.8); /* Ombra solo sopra */
}

.bold-text {
  font-weight: bold;
}
